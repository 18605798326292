import React, { Component} from 'react'
import {
	Card,
	Image,
} from 'semantic-ui-react'
import {Link} from 'gatsby'
import * as styles from './styles/blogList.module.css'
import {
	mobileMaxWidth,
	resizeTimer,
	tabletMaxWidth
} from '../../globalVar'


class BlogList extends Component {
	state = {
		windowWidth:undefined,
		timeOut1:undefined,
		itemsPerRow:this.props.initialShown
	}

	componentDidMount() {
		this.handler();
		window.addEventListener('resize', this.resizeTimerFunc)
	}
	componentWillUnmount() {
		clearTimeout(this.state.timeOut1);
		window.removeEventListener('resize', this.resizeTimerFunc);
	}

	resizeTimerFunc = () => {
		clearTimeout(this.state.timeOut1);
		const temp = setTimeout(this.handler, resizeTimer);
		this.setState({
			timeOut1:temp
		})
	}

	handler = () => {
		this.setState({
			windowWidth:window.innerWidth
		})
		if (window.innerWidth > tabletMaxWidth ) {
			this.setState({
				itemsPerRow: this.props.computerShown
			})
		} //tablet
		else if (window.innerWidth > mobileMaxWidth ) {
			this.setState({
				itemsPerRow: this.props.tabletShown
			})
		} else {  //mobile
			this.setState({
				itemsPerRow: this.props.mobileShown
			})
		}
	}

	recipePageCards = ({url, name, squareImageHref, summary, date}) =>{
		const {
			limitSummary,
			grid,
			cardsAsLinks,
			cardContentClassName,
			textClassName
		} = this.props
		const {
			windowWidth,
			itemsPerRow
		} = this.state

		let excerpt = ""
		if(summary) {
			if(limitSummary) {
				let containerWidth = 0;
				let fontSize = 0;
				if(windowWidth > 1200 ) {
					fontSize= 11 *6.5/10
					containerWidth = 1127
				}else if( windowWidth <= 1200 && windowWidth >= 992 ) {
					fontSize= 10.5 *6.5/10
					containerWidth = 933
				}else if( windowWidth <= 991 && windowWidth >= 768 ) {
					fontSize= 9.5 *6.5/10
					containerWidth = 723
				}else if( windowWidth < 768 ) {
					fontSize= 9.5 *6.5/10
					containerWidth = windowWidth-28
				}
				let space = (containerWidth* grid / 16 /itemsPerRow ) -15 /*-15 for margin*/
				space = Math.ceil( space / fontSize )
				let strings = summary.split(" ");
				let length = 0;
				let line = 1;
				for(let i=0;i<strings.length;i++) {
					if(line===3 && (length + strings[i].length +.5 > space-3) ) {

						const lastIndex = excerpt.lastIndexOf(" ");
						excerpt = excerpt.substr(0, lastIndex);
						excerpt = excerpt.concat("...");
						break;
					}else if( (length + strings[i].length +.5 > space) ) {
						line++;
						length = strings[i].length+.5
						excerpt = excerpt.concat(`${strings[i]} `);

					}else {
						length+=strings[i].length+.5;
						excerpt = excerpt.concat(`${strings[i]} `);
					}
				}
			}else {
				excerpt=summary
			}
		}else {
			excerpt=undefined
		}
		let CardProps = {}
		if( cardsAsLinks ) {
			CardProps.to=`/${url}/`
			CardProps.as=Link
		}
		return (
			<Card key={url} {...CardProps}>
				<Image src={squareImageHref} wrapped ui={false} />
				<Card.Content className={cardContentClassName? cardContentClassName:styles.cardContent}>
					<Card.Header className={textClassName ? textClassName:styles.cardText}>
						{name}
					</Card.Header>
					{date && (
						<Card.Meta className={textClassName ? textClassName:styles.cardText}>
							{date}
						</Card.Meta>
					)}
					{excerpt && (
						<Card.Description className={textClassName ? textClassName:styles.cardText}>
							{excerpt}
						</Card.Description>
					)}

				</Card.Content>
			</Card>
		)
	}


	render() {
		const {
			items,
		} = this.props
		const {
			itemsPerRow
		} = this.state
		const {
			recipePageCards
		} = this

		return (

			<Card.Group itemsPerRow={itemsPerRow}>
				{items.map(recipePageCards)}
			</Card.Group>

		)
	}
}

export default BlogList

BlogList.defaultProps = {
	grid:16,
	limitSummary:false,
	cardsAsLinks:true,
	initialShown:3,
	computerShown:4,
	tabletShown:3,
	mobileShown:2
}