import React, { Component} from 'react'
import {
	Breadcrumb,
	Container,
	Header,
	Icon,
	Pagination,
} from 'semantic-ui-react'
import {
	Link,
	navigate,
	graphql
} from 'gatsby'
import SEO from '../components/SEO'
import Layout from '../components/Layout'
import BlogList from '../components/BlogList'
import * as styles from './styles/template.module.css'
import {
	mobileMaxWidth,
	resizeTimer,
	tabletMaxWidth
} from '../globalVar'


export default class Recipes extends Component {
	state = {
		currentPage:1,
		limit:6,
		paginationRange:null,
		timeOut:null,
		blogPages:this.props.data.allMarkdownRemark.edges.filter((edges)=>{
			return edges.node.frontmatter.tags.indexOf(this.props.pageContext.mainTag) >= 0
		}),
	}

	componentDidMount() {
		this.handleParameters();
		this.handler();

		window.addEventListener('resize', this.resizeTimerFunc);
	}

	resizeTimerFunc = () => {
		clearTimeout(this.state.timeOut1);
		const temp = setTimeout(this.handler, resizeTimer);
		this.setState({
			timeOut1:temp
		})
	}

	componentWillUnmount() {
		clearTimeout(this.state.timeOut1);
		window.removeEventListener('resize', this.resizeTimerFunc);
	}

	handler = () => {
		//computer
		if (window.innerWidth > tabletMaxWidth ) {
			this.setState({
				paginationRange:4,
			})
		} //tablet
		else if (window.innerWidth > mobileMaxWidth ) {
			this.setState({
				paginationRange:2,
			})
		} else {  //mobile
			this.setState({
				paginationRange:1,
			})
		}
	}

	handleParameters = () => {
		const {location} = this.props
		if(location.search) {
			const parameters = location.search.split("&")
			/*important checks if current result is already matched,
			 *without this if statement, there'll be an infinite loop
			 */
			for( let i =0; i < parameters.length; i++) {
				if(parameters[i].split("=")[0] === "page" || parameters[i].split("=")[0] === "?page") {
					this.setState({ currentPage:parameters[i].split("=")[1] })
				}else {
					//unplanned parameters
				}
			}

		}
	}





	render() {

		const {
			currentPage,
			limit,
			paginationRange,
			blogPages,
		} = this.state
		let slicedRecipePage
		let length = 0
		let totalPages;

		slicedRecipePage = blogPages

		length = slicedRecipePage.length
		totalPages=Math.ceil(length/limit)
		slicedRecipePage = slicedRecipePage.slice( (currentPage-1)*limit, limit*currentPage)

		const convertedSlicedPages = slicedRecipePage.map((item)=>{
			return {
				squareImageHref:item.node.frontmatter.thumbnail,
				name:item.node.frontmatter.title,
				date:item.node.frontmatter.date,
				summary:item.node.frontmatter.excerpt,
				url:`recipes/${item.node.frontmatter.slug}`
			}
		})
		const location = this.props.location
		const {
			pageContext
		} = this.props
		return (
			<Layout location={location} backButton={true}>
				<SEO
					image={"https://cdn.ngnw.ph/images/category/c9581ac3/d88bcd08/banner/recipe%20and%20video.jpg"} imageWidth={1200} imageHeight={480}
					canonicalpath={pageContext.canonicalPath}
					title={`${pageContext.title} | Recipes and Videos`}
					description={pageContext.description}
				/>
				<Container className={styles.container}>
					<Breadcrumb className={styles.desktopOnly}>
						<Breadcrumb.Section content="Home" as={Link} to="/"/>
						<Breadcrumb.Divider />
						<Breadcrumb.Section content={pageContext.parenttitle} as={Link} to={"/"+pageContext.parenturl+"/"} />
						<Breadcrumb.Divider />
						<Breadcrumb.Section content={pageContext.title} />
					</Breadcrumb>
					<Breadcrumb className={styles.mobileOnly}>
						<Breadcrumb.Section as={Link} to={"/"+pageContext.parenturl+"/"} >
							<Icon name="arrow left" />
							{pageContext.parenttitle}
						</Breadcrumb.Section>
					</Breadcrumb>
					<Header className={styles.superHeaderMod + " " + styles.centerText }>
						<h1>{pageContext.title}</h1>
					</Header>
					<BlogList
						items={convertedSlicedPages}
						computerShown={3}
						tabletShown={2}
						mobileShown={1}
						initialShown={2}
					/>

					{totalPages > 1 && (
						<React.Fragment>
							<br/>
							<Pagination
								activePage={currentPage}
								onPageChange={(e, {activePage})=>{
									this.setState({
										currentPage:activePage
									})
									navigate(`${location.pathname}?page=${activePage}`)
								}}
								ellipsisItem={null}
								boundaryRange={null}
								siblingRange={paginationRange}
								totalPages={totalPages}
								pageItem={{
									className:styles.paginationItem
								}}
								firstItem={{
									className:styles.paginationItem,
									content:"<<"
								}}
								lastItem={{
									className:styles.paginationItem,
									content:">>"
								}}
								nextItem={{
									className:styles.paginationItem,
									content:">"
								}}
								prevItem={{
									className:styles.paginationItem,
									content:"<"
								}}
							/>
						</React.Fragment>
					)}
				</Container>
			</Layout>
		)
	}
}


export const data = graphql`
	query RecipeTagQuery($tag: String!) {
		allMarkdownRemark(
				filter: { frontmatter: { tags: { in: [$tag] } } }
				sort: {order:[ASC, DESC], fields:[frontmatter___order, frontmatter___date]}
		) {
			edges {
				node {
					frontmatter {
						slug
						title
						excerpt
						thumbnail
						date(formatString: "MMMM DD, YYYY")
						tags
					}
				}
			}
		}
	}
`